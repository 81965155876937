
.ant-menu .ant-menu-item,
.ant-menu .ant-menu-submenu {
    font-weight: bold;
}

.logo-container {
    overflow: hidden;
    padding-left: 2px;
    margin: 0px 4px 0px 4px;
    // border-right: solid 1px @border-color-split;
}

.App-logo {
    height: 56px;
    padding: 5px;
}

// .ant-menu .ant-menu-item-selected,
// .ant-menu-submenu-popup .ant-menu-item-selected {
//     background-color: #00205B;
// }

.ant-layout-sider .ant-menu .ant-menu-item .anticon,
.ant-layout-sider .ant-menu .ant-menu-submenu .anticon {
    font-size: 20px;
    line-height: 40px;
    vertical-align: middle;
}

.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-item {
    line-height: 25px;
    height: 25px;
}

.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-item a {
    padding-left: 6px;
    font-weight: normal;
}

.ant-layout-sider-collapsed {
    /*text-align: center;*/
    min-width: 70px;
    max-width: 70px;
    width: 70px;
    transition: .3s;
}

.ant-layout-sider-collapsed .ant-menu-inline-collapsed {
    width: 100%;
}

.ant-layout-sider-collapsed .ant-menu-item,
.ant-layout-sider-collapsed .ant-menu-submenu {
    text-overflow: clip;
}

.ant-layout-sider-collapsed .ant-menu-item .anticon,
.ant-layout-sider-collapsed .ant-menu-submenu .anticon {
    margin: 0;
}

.ant-layout-sider-collapsed .ant-menu-item span,
.ant-layout-sider-collapsed .ant-menu-submenu span {
    max-width: 0;
    display: inline-block;
}

.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0 24px !important;
}

.ant-layout-sider .ant-menu.ant-menu-root.ant-menu-inline,
.ant-layout-sider .ant-menu.ant-menu-root.ant-menu-inline-collapsed {
    min-height: calc(100vh - 64px);
    padding-bottom: 10px;
}

// Extra large devices (large desktops)
// No media query since the extra-large breakpoint has no upper bound on its width
// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}
// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}
// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .App-logo {
        height: 48px;
    }
}
// Extra small devices (portrait phones, less than 576px) (kinzler android tablets width = 601px
@media (max-width: 605px) {
}
