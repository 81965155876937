.highlight-machine-on {
    background-color: rgba(4, 32, 253, 0.2);
}

.highlight-machine-on > td.ant-table-column-sort {
    background-color: transparent;
}

.highlight-machine-off {
    background-color: rgba(253, 48, 4, 0.2);
}

.highlight-machine-off > td.ant-table-column-sort {
    background-color: transparent;
}

